//
// Loading bar
ngx-loading-bar
  color: var(--loading-bar-color) !important

  .ngx-bar
    background-color: var(--loading-bar-color) !important
    height: var(--loading-bar-height) !important

  .ngx-spinner
    display: var(--loading-bar-spinner-display) !important
    right: var(--loading-bar-spinner-right, 10px) !important
    left: var(--loading-bar-spinner-left, unset) !important
