[class^="scroll"], [class*=" scroll"]
  --scroll-shadow-size: 5px

  // add a padding to container to not cut off the shadow of its children
  // correct this by adding the same amount as negative margin
  &.has-shadow-children
    padding: var(--scroll-shadow-size)
    margin: calc(-1 * var(--scroll-shadow-size))

.scroll-y
  overflow-y: auto

.scroll-x
  overflow-x: auto

.scroll
  overflow: auto
