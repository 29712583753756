@forward 'tippy.js/dist/tippy.css'
@forward 'tippy.js/animations/shift-away.css'
@forward 'tippy.js/animations/shift-toward.css'

.tooltip
  &.is-tooltip-bottom
    &::before
      max-width: 100%
      width: 100%

// Avoid dotted underline on icons with tooltip
span[data-tooltip]
  &,
  &[class^="icon"],
  &[class*=" icon"]
    border-bottom: none
