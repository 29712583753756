.tabs
  &.is-toggle
    li
      &.is-active a
        background: var(--white)
        color: var(--black)
        border-color: var(--grey-lighter)

      a
        min-width: 90px
        color: var(--grey-lighter)
        border-color: var(--grey-lighter)

  a
    padding: 1em 2.7em
    max-width: 132.5px
