/* common */
.ribbon
  width: 150px
  height: 150px
  overflow: hidden
  position: absolute

  &::before,
  &::after
    position: absolute
    z-index: -1
    content: ''
    display: block
    border: 5px solid var(--text-dark)

.ribbon
  > span
    position: absolute
    display: block
    width: 220px
    padding: 10px 0
    background-color: var(--text-weak)
    box-shadow: 0 5px 10px rgba(0,0,0,.1)
    color: var(--white)
    text-shadow: 0 1px 1px rgba(0,0,0,.2)
    text-transform: uppercase
    text-align: center

  /* top left*/
  &.is-top-left
    top: -10px
    left: -10px

  &.is-top-left::before,
  &.is-top-left::after
    border-top-color: transparent
    border-left-color: transparent

  &.is-top-left::before
    top: 0
    right: 0

  &.is-top-left::after
    bottom: 0
    left: 0

  &.is-top-left span
    right: -22px
    top: 35px
    transform: rotate(-45deg)

  /* top right*/
  &.is-top-right
    top: -10px
    right: -10px

  &.is-top-right::before,
  &.is-top-right::after
    border-top-color: transparent
    border-right-color: transparent

  &.is-top-right::before
    top: 0
    left: 0

  &.is-top-right::after
    bottom: 0
    right: 0

  &.is-top-right span
    left: -25px
    top: 30px
    transform: rotate(45deg)
