@use 'bulma/sass/utilities/derived-variables' as vars

=badge-positions
  &.is-left
    bottom: 0
    left: 0
    right: auto
    top: 50%
    transform: translate(-50%, -50%)

  &.is-right
    bottom: auto
    left: auto
    right: 0
    top: 50%
    transform: translate(50%, -50%)

  &.is-top
    bottom: auto
    left: 50%
    right: auto
    top: 0
    transform: translate(-50%, -50%)

  &.is-top-left
    bottom: auto
    left: 0
    right: auto
    top: 0
    transform: translate(-50%, -50%)

  &.is-top-right
    bottom: auto
    left: auto
    right: 0
    top: 0
    transform: translate(50%, -50%)

  &.is-bottom
    bottom: 0
    left: 50%
    right: auto
    top: auto
    transform: translate(-50%, 50%)

  &.is-bottom-left
    bottom: 0
    left: 0
    right: auto
    top: auto
    transform: translate(-50%, 50%)

  &.is-bottom-right
    bottom: 0
    left: auto
    right: 0
    top: auto
    transform: translate(50%, 50%)

.badge
  background-color: var(--primary)
  border: 2px solid transparent
  border-radius: var(--badge-height)
  box-shadow: var(--badge-shadow)
  color: var(--scheme-main)
  font-size: var(--badge-font-size)
  height: var(--badge-height)
  line-height: calc(var(--badge-height) / 2 + 1px)
  min-width: var(--badge-height)
  overflow: hidden
  padding: var(--badge-padding)
  position: absolute
  right: 0
  text-overflow: ellipsis
  top: 0
  transform: translate(50%, -50%)
  white-space: nowrap

  &.is-outlined
    background-color: var(--white)
    border-color: var(--primary)
    color: var(--primary)

  +badge-positions

  @each $name, $pair in vars.$colors
    &.is-#{$name}
      &:not(.is-outlined)
        background-color: var(--#{$name})
        color: var(--#{$name}-invert)

      &.is-outlined
        border-color: var(--#{$name})
        color: var(--#{$name})

// Tabs position fix
.tabs
  li
    position: relative
