$_opacity: .4;
$_gutter-s: 8px;
$_gutter-m: 16px;
$_constant-border: 1px solid #cecece;

.modal {
  background-color: rgba(#000000, $_opacity);
  bottom: 0;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: auto;
  padding: 0 $_gutter-m;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10; /* Adjust according to your needs */
  text-align: center;

  &.fade-anim {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
    will-change: opacity;

    &.in {
      opacity: 1;
    }
  }

  .draggable {
    display: block;
    position: relative;

    .handle {
      cursor: move;
    }
  }
}

.modal-open {
  overflow: hidden;
}

.modal-content {
  background-color: #FFFFFF;
  border-radius: 4px;
  margin: $_gutter-m auto;
  max-width: 580px;
  position: relative;
  width: 100%;
  will-change: opacity;
  display: inline-block;
  text-align: left;

  &-size-m {
    max-width: 992px;
  }

  &-size-l {
    max-width: 1200px;
  }
}

.modal-header,
.modal-footer {
  align-items: center;
  display: flex;
  height: 56px;
  padding: 0 $_gutter-m;
}

.modal-header {
  border-bottom: $_constant-border;
  justify-content: center;

  h3 {
      color: inherit;
  }
}

.modal-body {
  padding: $_gutter-m;
}

.modal-footer {
  border-top: $_constant-border;
  justify-content: flex-end;

  .btn {
    margin: 0 0 0 $_gutter-s;
  }
}
