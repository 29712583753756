@use '../../mixins' as *

.groups-container
  .box
    &.highlight
      box-shadow: var(--box-link-active-shadow)
    &.is-shadowless
      border: 2px solid var(--scheme-main)
      &.highlight
        border-color: var(--primary)

  .border-bottom-container
    +mobile
      border-bottom-left-radius: 0 !important
      border-bottom-right-radius: 0 !important

.group-item-container
  &.selected
    box-shadow: 0 0 2px 1px var(--primary)

  &.last.selected
    border-bottom-left-radius: var(--box-radius)
    border-bottom-right-radius: var(--box-radius)

  &.group-item-added
    animation: isAdded 2s

  .group-item
    +desktop
      transition: margin-left 0.3s

@keyframes isAdded
  0%
    background-color: hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 25%), var(--primary-a))
  100%
    background-color: transparent
