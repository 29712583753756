/**
 * IMPORTANT NOTE
 *
 * This file overrides the default styles for the panel component of bulma.io.
 * The panel component is a simple container with a heading and a content.
 * Since we are not using bulmas panel component, we can safely override these styles.
 */

.panel
  --shadow: var(--panel-shadow, none)
  background-color: var(--panel-background-color)
  padding: var(--panel-padding)

  + .panel
    margin-top: var(--panel-gap)

.panel-header,
.panel-footer
  padding: var(--panel-header-padding)

.panel-content
  border: var(--panel-content-border)
  border-radius: var(--panel-content-border-radius)
  padding: var(--panel-content-padding)
  background-color: var(--panel-content-background-color)
  box-shadow: var(--panel-content-box-shadow)

.panel-content-separator
  position: relative
  margin-bottom: 2rem
  padding-bottom: 1rem

  &::after
    content: ''
    position: absolute
    left: calc(var(--panel-content-padding) * -1)
    right: calc(var(--panel-content-padding) * -1)
    bottom: 0
    height: 1px
    background-color: #eeeef0

.panel-title
  font-weight: normal
  text-wrap: balance
  margin: 0
