// Easy to use sidebar using bulma menu with:
//   - collapse/expandable sidebar using class `is-minimized`
//   - submenu toggling with class `is-active`
//   - top and bottom area to push items to bottom
//
//    <aside class="sidebar">
//      <div class="sidebar-top">
//        <div class="sidebar-header">
//          <a class="logo">
//            <img class="logo-icon" src="https://cdn.fino.cloud/img/logos/fino-logo.png" />
//          </a>
//        </div>
//        <ul class="menu-list">
//          <li>
//            <a href="#" class="item">
//              <span class="icon"><i class="fa fa-home"></i></span>
//              <span class="item-title">Home</span>
//            </a>
//          </li>
//          <li>
//            <a href="#" class="item is-active">
//              <span class="icon"><i class="fa fa-table"></i></span>
//              <span class="item-title">Links</span>
//            </a>
//            <ul>
//              <li>
//                <a href="#" class="item">
//                  <span class="icon is-small"><i class="fa fa-link"></i></span>
//                  <span class="item-title">Link1</span>
//                </a>
//              </li>
//            </ul>
//          </li>
//          <li>
//            <a href="#" class="item">
//              <span class="icon"><i class="fa fa-info"></i></span>
//              <span class="item-title">About</span>
//            </a>
//          </li>
//        </ul>
//      </div>
//      <div class="sidebar-bottom">
//        <ul class="menu-list">
//          <li>
//            <a href="#" class="item">
//              <span class="icon"><i class="fa fa-bars"></i></span>
//              <span class="item-title">Ausblenden</span>
//            </a>
//          </li>
//        </ul>
//      </div>
//    </aside>

.sidebar
  display: flex
  flex-direction: column
  flex-grow: 1
  justify-content: space-between
  min-height: 100%
  width: var(--sidebar-width)
  transition: width var(--transition-duration)

  &.is-minimized
    width: var(--sidebar-width-minimized)

    // Hide sidebar item titles
    .item-title
      opacity: 0

  .menu-list
    .item
      display: flex
      align-items: stretch
      flex-grow: 1

    .item-title
      display: flex
      align-items: center
      flex-grow: 1
      overflow: hidden
      white-space: nowrap

    .icon,
    .item-icon
      & ~ .item-title
        margin-left: 0.75em

.sidebar-top
  display: flex
  flex-direction: column
  flex-grow: 1
  min-height: 0
  min-width: 0

.sidebar-bottom
  display: flex
  flex-direction: column

.sidebar-header
  text-align: center

  .logo-icon
    height: 3rem
    max-width: 100%
