[class^="has-radius"], [class*=" has-radius"]
  border-radius: var(--radius)
  &.has-box-radius
    border-radius: var(--box-radius)

.has-radius-top-left
  border-radius: 0 !important
  border-top-left-radius: var(--radius) !important
  &.has-box-radius
    border-top-left-radius: var(--box-radius) !important
.has-radius-top-right
  border-radius: 0 !important
  border-top-right-radius: var(--radius) !important
  &.has-box-radius
    border-top-right-radius: var(--box-radius) !important
.has-radius-bottom-left
  border-radius: 0 !important
  border-bottom-left-radius: var(--radius) !important
  &.has-box-radius
    border-bottom-left-radius: var(--box-radius) !important
.has-radius-bottom-right
  border-radius: 0 !important
  border-bottom-right-radius: var(--radius) !important
  &.has-box-radius
    border-bottom-right-radius: var(--box-radius) !important
.has-radius-bottom
  border-radius: 0 !important
  border-bottom-right-radius: var(--radius) !important
  border-bottom-left-radius: var(--radius) !important
  &.has-box-radius
    border-bottom-right-radius: var(--box-radius) !important
    border-bottom-left-radius: var(--box-radius) !important
.has-radius-top
  border-radius: 0 !important
  border-top-right-radius: var(--radius) !important
  border-top-left-radius: var(--radius) !important
  &.has-box-radius
    border-top-right-radius: var(--box-radius) !important
    border-top-left-radius: var(--box-radius) !important
