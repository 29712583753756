@use 'bulma/sass/utilities/derived-variables' as vars

$timeline-dimensions: 16 24 32 48 64 96 128

.timeline
  display: flex
  flex-direction: column
  .timeline-header
    width: var(--timeline-header-width)
    min-width: var(--timeline-header-width)
    max-width: calc(var(--timeline-header-width) * 2)
    word-wrap: normal
    text-align: center
    display: flex
    justify-content: center
  .timeline-item
    display: flex
    display: -ms-flexbox
    display: -webkit-flex
    position: relative
    margin-left: var(--timeline-item-padding-left)
    padding-bottom: var(--timeline-item-padding-bottom)
    &::before
      content: ""
      background-color: var(--timeline-line-color)
      display: block
      width: var(--timeline-line-width)
      height: 100%
      position: absolute
      left: calc(var(--timeline-line-width) * -0.5)
      top: 0

    .timeline-marker
      position: absolute
      background: var(--timeline-marker-color)
      border: var(--timeline-marker-border)
      border-radius: 100%
      content: ""
      display: block
      height: var(--timeline-marker-size)
      transform: translateX(-50%)
      top: 1.2rem
      width: var(--timeline-marker-size)
      &.is-image
        background: var(--timeline-marker-color)
        border: var(--timeline-marker-border)
        border-radius: 100%
        display: block
        overflow: hidden
        @each $dim in $timeline-dimensions
          &.is-#{$dim}x#{$dim}
            height: calc($dim * 1px)
            width: calc($dim * 1px)
      &.is-icon
        display: flex
        align-items: center
        justify-content: center
        height: var(--timeline-marker-icon-size)
        width: var(--timeline-marker-icon-size)
        background: var(--timeline-marker-color)
        border: var(--timeline-marker-border)
        border-radius: 100%
        padding: .5rem
        > *
          font-size: var(--timeline-icon-size) !important
      &.is-outlined
        .image
          background: var(--white)
        &.is-icon
          background: var(--white)
          > *
            color: var(--timeline-marker-color)
      @each $name, $pair in vars.$colors
        &.is-#{$name}
          background-color: var(--#{$name}) !important
          border-color: var(--#{$name}) !important
          .image
            border-color: var(--#{$name}) !important

          &.is-icon
            background-color: var(--#{$name}) !important
            border-color: var(--#{$name}) !important
            > *
              color: var(--#{$name}-invert) !important
          &.is-outlined
            background-color: var(--white) !important
            border-color: var(--#{$name}) !important
            .image
              background-color: var(--white) !important
            &.is-icon
              background-color: var(--white) !important
              > *
                color: var(--#{$name}) !important
    .timeline-content
      padding: var(--timeline-content-padding)
      .heading
        font-weight: var(--weight-semibold)

    @each $name, $pair in vars.$colors
      &.is-#{$name}
        &::before
          background-color: var(--#{$name})

  &.is-centered
    .timeline-header
      display: flex
      width: 100%
      align-self: center
    .timeline-item
      width: 50%
      align-self: flex-end
      flex-direction: row
      &:nth-of-type(2n)
        align-self: flex-start
        flex-direction: row-reverse
        margin-left: 0
        margin-right: var(--timeline-item-padding-left)
        &::before
          right: calc(var(--timeline-line-width) * -0.5)
          left: auto
        .timeline-marker
          transform: translateX(50%)
        .timeline-content
          padding: var(--timeline-rtl-content-padding)
          text-align: right
          display: flex
          flex-direction: column
          align-items: flex-end
          flex-basis: 100%
      &:nth-of-type(2n+1)
        &::before
          content: ""
          background-color: var(--timeline-line-color)
          display: block
          width: var(--timeline-line-width)
          height: 100%
          position: absolute
          top: 0
      @each $name, $pair in vars.$colors
        &.is-#{$name}
          &::before
            background-color: var(--#{$name})

  &.is-rtl
    justify-content: flex-end
    align-items: flex-end
    .timeline-item
      justify-content: flex-end
      flex-direction: row
      border-left: none
      margin-left: 0
      margin-right: calc(var(--timeline-header-width) * 0.5)
      &::before
        right: 0
        left: auto
      .timeline-marker
        transform: translateX(50%)
        &.is-image
          @each $dim in $timeline-dimensions
            &.is-#{$dim}x#{$dim}
              transform: translateX(50%)
      .timeline-content
        padding: var(--timeline-rtl-content-padding)
        text-align: right

  &.no-headers
    .timeline-item
      &.is-first
        &::before
          height: calc(100% - 1.2rem)
          top: 1.2rem
      &.is-last
        &::before
          height: 1.2rem
