.flex
  display: flex

  &.stretch
    align-items: stretch

  &.center
    justify-content: center
    align-items: center

  &.align-center
    align-items: center

  &.align-end
    align-items: flex-end

  &.align-start
    align-items: flex-start

  &.align-space-between
    align-items: space-between

  &.align-space-around
    align-items: space-around

  &.justify-center
    justify-content: center

  &.justify-end
    justify-content: flex-end

  &.justify-space-between
    justify-content: space-between

  &.justify-space-around
    justify-content: space-around

  &.justify-start
    justify-content: flex-start

  &.has-columns
    flex-direction: column

  &.has-columns-reverse
    flex-direction: column-reverse

  &.has-rows
    flex-direction: row

  &.has-rows-reverse
    flex-direction: row-reverse

  .start-self
    align-self: flex-start

  .center-self
    align-self: center

  .end-self
    align-self: flex-end

  .favorite-child
    flex: 1 1 auto

  .equal-child
    flex: 1

  .favorite-child-no-overflow
    @extend .favorite-child

    position: relative
    overflow: hidden
    align-self: stretch

    & > div
      max-width: 100%
      height: 100%
      text-overflow: ellipsis
      overflow: hidden
      position: absolute
      top: 0
