@use 'bulma/sass/utilities/derived-variables' as vars

.divider
  position: relative
  display: flex
  align-items: center
  text-transform: uppercase
  color: var(--grey)
  font-size: var(--divider-font-size)
  font-weight: var(--weight-semibold)
  letter-spacing: .5px
  margin: var(--divider-margin-size) 0

  &::after,
  &::before
    content: ''
    display: block
    flex: 1
    height: 1px
    background-color: var(--divider-background-color)

  &:not(.is-right)
    &::after
      margin-left: var(--divider-margin-inner-size)

  &:not(.is-left)
    &::before
      margin-right: var(--divider-margin-inner-size)

  &.is-left
    &::before
       display: none

  &.is-right
    &::after
       display: none

  &.is-vertical
    flex-direction: column
    margin: 0 var(--divider-margin-size)

    &::after,
    &::before
      height: auto
      width: 1px

    &::after
      margin-left: 0
      margin-top: var(--divider-margin-inner-size)

    &::before
      margin-right: 0
      margin-bottom: var(--divider-margin-inner-size)

  @each $name, $pair in vars.$colors
    &.is-#{$name}
      &::after,
      &::before
        background-color: var(--#{$name})
