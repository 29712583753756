.box
  &.is-selectable
    border-color: var(--border)
    box-shadow: none
    transition: all 0.2s ease-in-out

    &.is-selected
      border-color: var(--border-hover)
      box-shadow: var(--box-shadow)

      &.is-primary
        background-color: var(--primary-light)
        border-color: var(--primary)
        box-shadow: 0 4px 6px var(--primary-shadow)
        color: var(--primary-invert)

    &:hover
      border-color: var(--border-hover)
      box-shadow: var(--box-shadow)
