=rotate($angle)
  transform: rotate($angle)

=arrowSize($arrowSize: 20px, $arrowBorderSize: 3px, $arrowBarWidth: 2px, $arrowBarHeight: 25px)
  display: inline-block
  height: $arrowSize
  position: relative
  width: $arrowSize

  &:after
    border-bottom-style: solid
    border-bottom-width: $arrowBorderSize
    border-right-style: solid
    border-right-width: $arrowBorderSize
    content: ''
    display: inline-block
    height: $arrowSize
    left: 0
    position: absolute
    top: 0
    width: $arrowSize

  // Hide arrow, only show arrow bar
  &.no-arrow
    &:after
      display: none

  // Triangle shape state
  &.is-triangle:after
    border-style: solid
    border-width: 0 0 $arrowSize $arrowSize
    height: 0
    width: 0

  // Check shape state
  &.is-check:after
    width: calc($arrowSize / 2)

  // Arrow Bar
  &.arrow-bar:before
    bottom: 1px
    content: ''
    height: $arrowBarHeight
    position: absolute
    right: 0
    transform-origin: bottom
    width: $arrowBarWidth
    @include rotate(-45deg)

  // States
  &.is-top
    @include rotate(225deg)

  &.is-right
    @include rotate(315deg)

  &.is-bottom
    @include rotate(45deg)

  &.is-left
    @include rotate(135deg)

=arrowColor($color)
  &,
  .arrow
    &:after
      border-color: $color

    &.is-triangle:after
      border-color: transparent
      border-bottom-color: $color

    &:before
      background-color: $color
