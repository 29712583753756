@use 'bulma/sass/utilities/derived-variables' as vars

:root
  --ribbon-color: var(--grey-darker)
  --ribbon-background-color: var(--white)
  --ribbon-border: .1rem solid var(--grey-lighter)

// The ribbon sizes use mixins so they can be used at different breakpoints
=ribbon-small
  font-size: vars.$size-small
=ribbon-medium
  font-size: vars.$size-medium
=ribbon-large
  font-size: vars.$size-large

.has-ribbon
  position: relative

.has-ribbon-left
  position: relative
  .ribbon-tag
    right: auto
    left: 0
    border-left: none
    border-right: var(--ribbon-border)

.has-ribbon-bottom
  position: relative
  .ribbon-tag
    top: auto
    bottom: .5em

.ribbon-tag
  background-color: var(--ribbon-background-color)
  border: var(--ribbon-border)
  border-right: none
  color: var(--ribbon-color)
  font-size: vars.$size-normal
  justify-content: center
  padding-left: 0.75em
  padding-right: 0.75em
  text-align: center
  white-space: nowrap
  position: absolute
  top: .5em
  right: 0
  font-weight: 400
  z-index: 2

  @each $name, $pair in vars.$colors
    &.is-#{$name}:not(.is-outlined)
      background-color: var(--#{$name})
      border-color: transparent
      color: var(--#{$name}-invert) !important
    &.is-#{$name}
      &.is-outlined
        background-color: transparent
        border-color: var(--#{$name})

  // Sizes
  &.is-small
    +ribbon-small
  &.is-medium
    +ribbon-medium
  &.is-large
    +ribbon-large
  &.is-outlined
    background-color: transparent

