@use '../../mixins' as *

.header
  // Default margin bottom for header
  margin-bottom: var(--header-margin-bottom)

  +mobile
    margin-bottom: var(--header-margin-bottom-mobile)

  +touch
    .title,
    .subtitle
      &.is-responsive
        font-size: 87.5%

  // Spacing between title and subtitle when not spaced
  .title:not(.is-spaced):has(+ .subtitle)
    margin-bottom: var(--header-title-margin-bottom)

  // Gap between title and small title
  .title span + small
    color: var(--subtitle-color)
    font-weight: normal
    margin-left: var(--header-title-small-margin-left)

  // Align header icon and title
  .title
    &.has-icon
      display: flex
      align-items: center
      gap: var(--header-title-icon-gap, .5em)

      .icon
        align-items: center
        display: inline-flex
        flex-shrink: 0
        justify-content: center
        height: var(--header-title-icon-dimensions, var(--icon-dimensions))
        width: var(--header-title-icon-dimensions, var(--icon-dimensions))

  // Improve readability of header title and subtitle when they wrap on multiple lines
  .title,
  .subtitle
    text-wrap: balance
