@use '../../mixins' as *

.freshdesk-faqs-button
  background-color: var(--primary-light)
  border-radius: 30px 8px 30px 30px
  color: var(--primary-dark)
  transition: all 0.1s ease-out 0s
  min-width: 5rem

  .icon
    margin-top: 0.4rem

.freshdesk-faqs-modal
  background-color: var(--grey-lighter)
  border-radius: var(--radius)
  height: var(--faq-height, 600px)
  width: var(--faq-width, 500px)
  position: absolute
  right: 0
  bottom: 0

  +mobile
    height: 100vh
    width: 100vw
    position: fixed

  .heading-container
    background-color: #A5A5A5
    border-top-left-radius: var(--radius)
    border-top-right-radius: var(--radius)
    color: var(--white-ter)
    height: 140px
    padding: 2rem 1.5rem

    .close-modal-button
      position: absolute
      top: 25px
      right: 5px

  .body-container
    background-color: var(--white)
    border-radius: var(--radius)
    display: flex
    flex-direction: column
    height: 80%
    position: relative
    top: -50px
    z-index: 999

  .menu-list .icon
    --icon-dimensions: 1rem
    min-width: var(--icon-dimensions)
