@use 'ngx-toastr/toastr'

@keyframes fadeinup
  0%
    opacity: 0
    transform: translateY(25%)
  100%
    opacity: 1
    transform: translateY(0)

@keyframes fadeoutdown
  0%
    opacity: 1
    transform: translateY(0)
  100%
    opacity: 0
    transform: translateY(25%)

@keyframes ripple
  0%
    transform: scale(0) translateY(-45%) translateX(13%)
  100%
    transform: scale(1) translateY(-45%) translateX(13%)

.notyf__icon--type,
.notyf__icon--type
  background: white
  border-radius: 50%
  display: block
  height: 21px
  margin: 0 auto
  position: relative
  width: 21px

.notyf.error
  .notyf__icon--type:after,
  .notyf__icon--type:before
    background: currentColor
    border-radius: var(--radius)
    content: ""
    display: block
    height: 12px
    left: 9px
    position: absolute
    top: 5px
    width: 3px

  .notyf__icon--type
    color: var(--danger)
    &:after
      transform: rotate(-45deg)
    &:before
      transform: rotate(45deg)

  .notyf__ripple
    background: var(--danger)

  .notyf__toast
    color: var(--danger-invert)

.notyf.success
  .notyf__icon--type:after,
  .notyf__icon--type:before
    background: currentColor
    border-radius: var(--radius)
    content: ""
    display: block
    position: absolute
    width: 3px

  .notyf__icon--type
    color: var(--success)
    &:after
      height: 6px
      transform: rotate(-45deg)
      top: 9px
      left: 6px
    &:before
      height: 11px
      transform: rotate(45deg)
      top: 5px
      left: 10px

  .notyf__ripple
    background: var(--success)

  .notyf__toast
    color: var(--success-invert)

.notyf.warning
  .notyf__icon--type
    color: var(--warning)
    display: none

  .notyf__ripple
    background: var(--warning)

  .notyf__toast
    color: var(--warning-invert)

.notfy.message
  .notyf__icon--type
    color: var(--info)
    display: none

  .notyf__ripple
    background: var(--info)

  .notyf__toast
    color: var(--info-invert)

.notyf__toast
  border-radius: var(--radius)
  bottom: -100%
  box-shadow: var(--box-shadow)
  color: var(--text-invert)
  display: block
  margin-top: 12px
  max-width: initial
  overflow: hidden
  padding: 0 12px
  position: relative
  width: 300px

.notyf__ripple
  animation-duration: 0.5s
  animation: ripple 0.4s ease-out forwards
  border-radius: 50%
  height: 400px
  height: 600px
  position: absolute
  right: 0
  top: 0
  transform-origin: bottom right
  transform: scale(0) translateY(-51%) translateX(13%)
  width: 400px
  width: 600px
  z-index: 5

.notyf__wrapper
  align-items: center
  border-radius: var(--radius)
  display: flex
  padding-bottom: 20px
  padding-right: 15px
  padding-top: 20px
  position: relative
  z-index: 10

.notyf__icon
  animation-delay: 0.3s
  animation: fadeinup 0.3s forwards
  font-size: 1.3em
  margin-right: 12px
  opacity: 0
  text-align: center
  width: 22px

.notyf__message
  animation-delay: 0.25s
  animation: fadeinup 0.3s forwards
  opacity: 0
  position: relative
  vertical-align: middle

@media only screen and (max-width: 736px)
  .notyf
    align-items: initial
    bottom: 0
    left: 0
    right: 0

  .notyf__ripple
    animation-duration: 0.5s
    height: 600px
    width: 600px

  .notyf__toast
    border-radius: 0
    box-shadow: var(--box-shadow)
    max-width: initial
