@use 'bulma/sass/utilities/initial-variables' as vars
@use '../../mixins' as *

.footer > .columns
  container-type: inline-size

  > .column
    &.left
      order: 1
    &.center
      order: 2
    &.right
      order: 3

    // Change column order on mobile so we have center on top,
    // left and right on second order.
    +mobile
      &.left
        order: 2
      &.center
        order: 1
      &.right
        order: 2
      // Hide empty columns on mobile
      &:empty
        display: none

  // Container size of tablet is the same as mobile
  @container (max-inline-size: #{vars.$tablet})
    .column
      &.left
        order: 2
      &.center
        order: 1
      &.right
        order: 2
      // Hide empty columns on mobile
      &:empty
        display: none
