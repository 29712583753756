@use 'bulma/sass/utilities/derived-variables' as vars
@use '../../mixins' as *

$steps-default-color: var(--grey-lighter)
$steps-completed-color: var(--primary)
$steps-active-color: var(--primary)
$steps-horizontal-min-width: 10em
$steps-vertical-min-height: 4em
$steps-marker-size: 2
$steps-divider-size: .4em
$steps-gap-size: .3rem
$steps-hollow-border-size: 2px
$steps-thin-divider-size: 1px
$steps-thin-marker-size: .8em

=steps-horizontal
  +tablet
    &:not(.is-vertical)
      @content
  &.is-horizontal
    @content

=steps-vertical
  +mobile
    &:not(.is-horizontal)
      @content
  &.is-vertical
    @content

=steps-all-segments
  &.steps .steps-segment
    @content

=steps-active-segment
  &.steps .steps-segment.is-active
    @content

=steps-inactive-segments
  &.steps .steps-segment.is-active ~ .steps-segment
    @content

.steps
  +block

  .steps-segment
    position: relative
    &:not(:last-child):after
      // This will contain the horizontal or vertical divider
      content: " "
      display: block
      position: absolute

  +steps-vertical
    .steps-segment
      display: block
      &:not(:last-child)
        min-height: var(--steps-vertical-min-height)

  +steps-horizontal
    display: flex
    .steps-segment:not(:last-child)
      flex-basis: 1rem
      flex-grow: 1
      flex-shrink: 1

    &.is-narrow
      .steps-segment
        flex-grow: 0
        &:not(:last-child)
          min-width: var(--steps-horizontal-min-width)

    &.is-narrow.is-centered
      justify-content: center

    &.is-narrow.is-right
      justify-content: flex-end

    &.has-content-centered
      text-align: center

    &.is-balanced, &.has-content-centered
      .steps-segment:last-child
        flex-basis: 1rem
        flex-grow: 1
        flex-shrink: 1

    &.is-narrow.has-content-centered
      .steps-segment:last-child
        flex-grow: 0
        min-width: var(--steps-horizontal-min-width)

    .steps-content.is-divider-content
      text-align: center

  // Marker style
  .steps-marker
    align-items: center
    display: flex
    border-radius: 50%
    font-weight: var(--weight-bold)
    justify-content: center
    // Position & z-index are needed to put it above the divider
    position: relative
    z-index: 10


// Use a mixin to define all ratios in the same spot
=steps-size($size)
  font-size: $size

  .steps-marker
    height: $size * $steps-marker-size
    width: $size * $steps-marker-size

  +steps-vertical
    // Draw a vertical divider
    .steps-segment:not(:last-child):after
      // top and bottom position is almost touching the inner side of a hollow border marker
      bottom: calc(var(steps-gap-size) * -0.8)
      left: calc($size * $steps-marker-size * 0.5 - $steps-divider-size * 0.5)
      top: calc($size * $steps-marker-size - $steps-gap-size * 0.8)
      width: $steps-divider-size

    // Align the content with the marker
    .steps-content
      margin-left: calc(#{$size * $steps-marker-size * 0.5} + .5em)
      margin-top: calc(-#{$size * $steps-marker-size})
      padding-left: 1em
      padding-bottom: 1em

    .steps-content.is-divider-content
      margin-top: 0
      padding-bottom: 0

    &.has-content-centered
      .steps-content
        padding-top: calc(#{$size * $steps-marker-size * 0.5} - .5em)

    &:not(.is-thin)
      &.has-gaps .steps-segment, .steps-segment.has-gaps
        &:not(:last-child):after
          top: ($size * $steps-marker-size) + $steps-gap-size
          bottom: $steps-gap-size

  +steps-horizontal
    // Draw a horizontal divider
    .steps-segment:not(:last-child):after
      height: $steps-divider-size
      // top and bottom position is almost touching the inner side of a hollow border marker
      left: calc($size * $steps-marker-size - $steps-gap-size * 0.8)
      right: -calc(#{$steps-gap-size * 0.8})
      top: calc(#{$size * ($steps-marker-size * 0.5)} - (#{$steps-divider-size * 0.5}))

    // Align the content with the marker
    .steps-content
      margin-left: calc($size * $steps-marker-size * 0.5)
      &:not(:last-child)
        margin-right: calc(-#{$size * $steps-marker-size * 0.5})

      &.is-divider-content
        margin-right: calc(-#{$size * $steps-marker-size * 0.5})
        padding-left: 2em
        padding-right: 2em

    &.has-content-centered
      .steps-segment:not(:last-child):after
        left: 50%
        right: -50%
      .steps-marker
        position: absolute
        left: calc(50% - #{$size * $steps-marker-size * 0.5})
      .steps-content
        margin-top: $size * $steps-marker-size
        margin-left: .5em
        margin-right: .5em
        padding-top: .2em

    &:not(.is-thin)
      &.has-gaps .steps-segment, .steps-segment.has-gaps
        &:not(:last-child):after
          left: calc(($size * $steps-marker-size) + $steps-gap-size)
          right: $steps-gap-size

      &.has-content-centered
        &.has-gaps .steps-segment, .steps-segment.has-gaps
          &:not(:last-child):after
            left: calc(50% + #{($size * $steps-marker-size * 0.5) + $steps-gap-size})
            right: calc(-50% + #{($size * $steps-marker-size * 0.5) + $steps-gap-size})

.steps
  +steps-size(vars.$size-normal)

.steps.is-small
  +steps-size(vars.$size-small)

.steps.is-medium
  +steps-size(vars.$size-medium)

.steps.is-large
  +steps-size(vars.$size-large)


// Divider Default Colors
.steps-segment
  &:after
    background-color: $steps-completed-color

  &.is-active
    &:after
      background-color: $steps-default-color

  &.is-active ~ .steps-segment
    &:after
      background-color: $steps-default-color

// Marker Default Colors
.steps:not(.is-hollow)
  .steps-marker:not(.is-hollow)
    background-color: $steps-completed-color
    color: findColorInvert($steps-completed-color)

  .steps-segment.is-active
    .steps-marker:not(.is-hollow)
      background-color: $steps-active-color
      color: findColorInvert($steps-active-color)

  .steps-segment.is-active
    & ~ .steps-segment .steps-marker:not(.is-hollow)
      background-color: $steps-default-color
      color: findColorInvert($steps-default-color)

// Hollow style
.steps.is-hollow .steps-marker,
.steps-marker.is-hollow
  border: $steps-hollow-border-size solid $steps-completed-color

.steps.is-hollow .is-active .steps-marker,
.steps .is-active .steps-marker.is-hollow
  border-color: $steps-active-color

.steps.is-hollow .steps-segment.is-active ~ .steps-segment .steps-marker,
.steps-segment.is-active ~ .steps-segment .steps-marker.is-hollow
  border-color: $steps-default-color

// Override marker color per step
@each $name, $pair in vars.$colors
  .steps:not(.is-hollow) .steps-marker:not(.is-hollow)
    &.is-#{$name}
      background-color: var(--#{$name}) !important
      color: var(--#{$name}-invert) !important

  .steps.is-hollow .steps-marker,
  .steps .steps-marker.is-hollow
    &.is-#{$name}
      border-color: var(--#{$name}) !important


// Divider dashed style
=steps-divider-background($angle, $color, $size)
  background: repeating-linear-gradient($angle, $color, $color $size, transparent $size, transparent #{$size * 2})
.steps
  +steps-vertical
    &.is-dashed .steps-segment, .steps-segment.is-dashed
      &:after
        +steps-divider-background(0deg, $steps-completed-color, 5px)

    &.is-dashed .steps-segment.is-active,
    &.is-dashed .steps-segment.is-active ~ .steps-segment,
    .steps-segment.is-active.is-dashed,
    .steps-segment.is-active ~ .steps-segment.is-dashed
      &:after
        +steps-divider-background(0deg, $steps-default-color, 5px)

  +steps-horizontal
    &.is-dashed .steps-segment,
    .steps-segment.is-dashed
      &:after
        +steps-divider-background(90deg, $steps-completed-color, 10px)

    // the active segment and any subsequent segment get the default colot
    &.is-dashed .steps-segment.is-active,
    &.is-dashed .steps-segment.is-active ~ .steps-segment,
    .steps-segment.is-active.is-dashed,
    .steps-segment.is-active ~ .steps-segment.is-dashed
      &:after
        +steps-divider-background(90deg, $steps-default-color, 10px)

// Thin modifier
.steps.is-thin
  .steps-marker
    width: $steps-thin-marker-size
    height: $steps-thin-marker-size
  &.is-hollow .steps-marker,
  .steps-marker.is-hollow
    border-width: $steps-thin-divider-size
    height: calc(#{$steps-thin-marker-size} + #{$steps-thin-divider-size})
    width: calc(#{$steps-thin-marker-size} + #{$steps-thin-divider-size})

  +steps-vertical
    .steps-segment
      &:not(:last-child):after
        bottom: 0
        left: calc(#{$steps-thin-marker-size * 0.5} - #{$steps-thin-divider-size * 0.5})
        top: $steps-thin-marker-size
        width: $steps-thin-divider-size

    .steps-content
      margin-top: -$steps-thin-marker-size * 1.5
      margin-left: $steps-thin-marker-size

    &.has-gaps .steps-segment, .steps-segment.has-gaps
      &:not(:last-child):after
        bottom: calc($steps-thin-marker-size / 2)
        top: $steps-thin-marker-size * 1.5

    &.has-content-centered
      .steps-content
        padding-top: $steps-thin-marker-size * 2

  +steps-horizontal
    .steps-segment
      &:not(:last-child):after
        left: $steps-thin-marker-size
        right: 0
        top: calc(#{$steps-thin-marker-size * 0.5} - #{$steps-thin-divider-size * 0.5})
        height: $steps-thin-divider-size

    .steps-content
      margin-top: $steps-thin-marker-size

    &.has-content-centered
      .steps-segment
        &:not(:last-child):after
          left: calc(50% + #{$steps-thin-marker-size * 0.5})
          right: calc(-50% + #{$steps-thin-marker-size * 0.5})
      .steps-marker
        position: absolute
        left: calc(50% - #{$steps-thin-marker-size * 0.5})

    &.has-gaps .steps-segment, .steps-segment.has-gaps
      &:not(:last-child):after
        left: $steps-thin-marker-size * 1.5
        right: $steps-thin-marker-size * 0.5

    &.has-content-centered.has-gaps .steps-segment, &.has-content-centered .steps-segment.has-gaps
      &:not(:last-child):after
        left: calc(50% + #{($steps-thin-marker-size * 0.5) + ($steps-thin-marker-size * 0.5)})
        right: calc(-50% + #{($steps-thin-marker-size * 0.5) + ($steps-thin-marker-size * 0.5)})
