@keyframes scale-up
  0%
    transform: scale(0.5)
  100%
    transform: scale(1)

@keyframes scale-up
  0%
    transform: scale(0.5)
  100%
    transform: scale(1)

.scale-up
  animation: scale-up 0.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both
