@use 'sass:list'
@use '../../mixins' as mixins

// Use highcharts palette helper:
// @see https://www.highcharts.com/samples/highcharts/css/palette-helper
// @see https://www.learnui.design/tools/data-color-picker.html
//
// NOTE: Sync these colors with Colors in `chart.config.ts` file
//
$highcharts-colors: var(--primary), #544fc5, #ec838a, #cd69cb, #8b73d9, #2678d4, #ffa435, #c4688c, #79ade7, #78a8d1, #6e7fad, #7d7bd4, #977dd5, #b27fd6, #c97fd7, #e07fd8
// harmonic colors: #b9d5b2, #84b29e, #568f8b, #326b77, #326b77, #2f4858

:root,
.highcharts-light,
.highcharts-dark
  // UI colors
  --highcharts-background-color: #ffffff

  // Colors for data series and points
  @for $i from 1 through list.length($highcharts-colors)
    $color: list.nth($highcharts-colors, $i)
    --highcharts-color-#{$i - 1}: #{$color}

//
// Chart styles
//
.chart
  width: 100%
  max-width: 100%

.chart-container + .chart-container
  margin-top: 3rem

highcharts-chart
  display: block
  width: 100%
  height: 100%

  $colors: success, success-light, success-dark, danger, danger-light, danger-dark, primary, primary-light, primary-dark, secondary, accent, grey-light

  @for $i from 1 through list.length($colors)
    $color: list.nth($colors, $i)
    // Colorize data series
    .is-#{$color}
      .highcharts-point
        fill: var(--#{$color})
      .highcharts-graph
        stroke: var(--#{$color})
      .highcharts-halo
        fill: var(--#{$color})
        stroke: var(--#{$color})

      // Colorize data points on line series
      &.highcharts-line-series
        .highcharts-point
          stroke: var(--#{$color})

  // Colorize legend items
  .highcharts-legend-item
    cursor: pointer
    @for $i from 1 through list.length($colors)
      $color: list.nth($colors, $i)
      &.is-#{$color}
        .highcharts-point
          stroke: var(--#{$color})

// Style chart container
.highcharts-container,
.highcharts-root
  font-family: var(--family-primary)

// Style tooltip
.highcharts-tooltip,
.highcharts-tooltip-box
  > span
    @include mixins.tooltip
    padding: var(--tooltip-padding)

  .title, .subtitle, .text
    color: var(--tooltip-color)

  table, thead, tbody, tfoot, td, th
    @include mixins.tooltip
    border-width: 0
  th
    border-bottom: 1px solid var(--tooltip-border-color)

// Style axis lines and grid lines
.highcharts-axis-line,
.highcharts-grid-line,
.highcharts-tick
  stroke: var(--highcharts-neutral-color-10)

// Style data labels
text.highcharts-data-label,
.highcharts-data-label text,
.highcharts-legend-item > text
  font-weight: normal

// Define gradients
@for $i from 1 through list.length($highcharts-colors)
  $index: $i - 1

  #gradient-#{$index} stop
    stop-color: var(--highcharts-color-#{$index})

  #gradient-#{$index} stop[offset="0"]
    stop-opacity: 0.5

  #gradient-#{$index} stop[offset="1"]
    stop-opacity: 0

  // Apply gradients
  .highcharts-color-#{$index} .highcharts-area
    fill-opacity: 1
    fill: url(#gradient-#{$index})

  // Colorize markers/tracker points in areaspline series
  .highcharts-areaspline-series
    &.highcharts-markers.highcharts-tracker
      &.highcharts-color-#{$index}
        .highcharts-point
          fill: var(--highcharts-color-#{$index})
          stroke: var(--highcharts-color-#{$index})

.highcharts-map-series .highcharts-point.highcharts-point-hover
  fill: var(--primary-soft) !important
  stroke: var(--primary) !important
