@use '../../mixins' as *

/**
 * Layout adaptions
 */
.layout
  &.has-background-image
    background-size: cover
    background-position: center

    // Unset background color for all layout children
    .layout-main,
    .layout-footer,
    .footer
      background-color: transparent

  &.has-frame
    background-color: var(--layout-frame-background-color)
    border: 1px solid var(--layout-frame-border)
    border-radius: var(--layout-frame-border-radius)
    box-shadow: var(--layout-shadow)
    padding: var(--layout-frame-padding)

    .layout-content
      border-radius: var(--layout-frame-content-border-radius)

.layout-header
  background-color: var(--layout-header-background-color)
  border-bottom: var(--layout-border)

  .navbar
    // Colorize navbar with theme primary color
    border-bottom: 2px solid var(--primary)
    padding: var(--layout-header-navbar-padding, 0 1rem)

.layout-content
  background-color: var(--layout-content-background-color)
  border: var(--layout-content-border)
  border-radius: var(--layout-content-border-radius)

.layout-footer
  background-color: var(--layout-footer-background-color)
  border-top: var(--layout-border)
  +mobile
    background-color: var(--layout-footer-background-color-mobile, var(--layout-footer-background-color))

  .footer
    --footer-background-color: var(--layout-footer-background-color)

.layout-sidebar
  background-color: var(--layout-sidebar-background-color)
  background-image: var(--layout-sidebar-background-image)
  border-right: var(--layout-border)
  box-shadow: var(--layout-sidebar-shadow)
  +mobile
    background-color: var(--layout-sidebar-background-color-mobile, var(--layout-sidebar-background-color))
    background-image: var(--layout-sidebar-background-image-mobile, var(--layout-sidebar-background-image))

  .footer
    --footer-background-color: var(--layout-sidebar-background-color)

.layout-content
  box-shadow: var(--layout-content-shadow)

.layout-content-footer
  .footer
    background-color: var(--layout-content-footer-background-color, var(--layout-footer-background-color, var(--footer-background-color)))
    padding: var(--layout-content-footer-padding, var(--footer-padding))
