@use '../../mixins' as *

/**
 * Holy grail layout (using CSS grid)
 *
 * |-----------------------------------------------------|
 * | .header                                             |
 * |-----------------------------------------------------|
 * | .sidebar     | .content                             |
 * |              |                                      |
 * |              |                                      |
 * |              |                                      |
 * |              | .content-footer                      |
 * |-----------------------------------------------------|
 * | .footer                                             |
 * |-----------------------------------------------------|
 *
 *  - simple and flexible
 *  - header, sidebar, and footer
 *  - sticky footer inside main content
 *  - scrollable sidebar
 *  - strechted sidebar and main content panels (full height background color)
 *  - basic markdown template:
 *  ```html
    <div class="layout has-sidebar">
      <header class="layout-header">
        <p>.header</p>
      </header>
      <main class="layout-main">
        <aside class="layout-sidebar">
          <p>.sidebar</p>
        </aside>
        <section class="layout-content">
          <div class="layout-content-body">
            <p>.content</p>
          </div>
          <footer class="layout-content-footer">
            <p>.footer</p>
          </footer>
        </section>
      </main>
      <footer class="layout-footer">
        <p>.footer</p>
      </footer>
    </div>
 *  ```
 */

html
  height: 100%

  // NOTE: overflow: auto prevents the layout has-sticky-header from
  // working properly. This is because the header is fixed and the body
  // is scrollable. To fix this, we use overscroll-behavior: none to
  // prevent the body from scrolling when the header is fixed.
  overscroll-behavior: none

body
  min-height: 100%

// Grid layout to stetch body and align footer at the bottom
=grid-layout-content-footer
  display: grid
  grid-template-columns: 100%
  grid-template-rows: 1fr auto
  grid-template-areas: 'content-body' 'content-footer'

.layout
  height: fit-content
  min-height: 100vh
  width: 100%
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: min-content 1fr min-content
  grid-template-areas: 'header' 'main' 'footer'

  &.is-embedded
    // Reset min-height of layout and layout-main for embedded layouts
    &, .layout-main
      min-height: initial

    // Reset overflow of layout-content for embedded layouts
    &.has-sidebar > .layout-main > .layout-content
      overflow: initial

  // NOTE: display: none is used to hide the sidebar by default. This is
  // necessary to prevent the sidebar from being visible during page load.
  // Use the .has-sidebar class on the layout to show the sidebar.
  &:not(.has-sidebar)
    .layout-sidebar
      display: none

  // When layout main has a sidebar, the main content is split into two columns
  // &:has(> .layout-main > .layout-sidebar) > .layout-main
  &.has-sidebar
    &.is-sidebar-minimized
      --layout-sidebar-width: var(--layout-sidebar-width-minimized)

    .layout-sidebar
      display: block

    > .layout-main
      grid-template-rows: 1fr
      grid-template-columns: var(--layout-sidebar-width, 1fr) 3fr

      > .layout-content
        overflow-y: auto

  // To make the sidebar fixed, add the .is-sidebar-fixed class to the layout
  &.is-sidebar-fixed
    height: calc(100vh - var(--layout-header-height, 0px))
    min-height: initial

  &.has-sticky-header
    > .layout-header
      position: fixed
      top: 0
      left: 0
      right: 0
      z-index: 10

    > .layout-main > .layout-content
      // Add padding to the top of the main content to prevent the header from
      // overlapping the content.
      padding-top: var(--layout-header-height)

  &.is-header-hidden
    .layout-header
      transform: translateY(-100%)

  +mobile
    --layout-header-height: var(--layout-header-height-mobile)
    grid-template-columns: 100%

    // When layout main has a sidebar, the main content is split into two rows
    &:has(.layout-main > .layout-sidebar) > .layout-main
      grid-template-columns: 100%
      grid-template-rows: auto

.layout-header
  grid-area: header
  height: var(--layout-header-height)
  min-height: var(--layout-header-height)

  // Transform transition to show/hide sticky header on scroll
  transition: transform 0.25s

  +mobile
    --layout-header-height: var(--layout-header-height-mobile)

.layout-footer
  grid-area: footer

.layout-main
  grid-area: main
  display: grid
  min-height: calc(100vh - var(--layout-header-height, 0px))

.layout-sidebar
  overflow-y: auto

.layout-content
  &.is-content-centered
    display: flex
    align-items: center
    justify-content: center

  // When content has a footer, the content is split into two rows.
  &:has(.layout-content-footer)
    +grid-layout-content-footer

  // Because Firefox doesn't support :has yet, we use a class instead.
  &.has-content-footer
    +grid-layout-content-footer

.layout-content-body
  grid-area: content-body

.layout-content-footer
  grid-area: content-footer
