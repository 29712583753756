[class^="has-border"],
[class*=" has-border"]
  border-color: var(--border)
  &.has-radius
    border-radius: var(--radius)
  &.has-box-radius
    border-radius: var(--box-radius)

.has-border
  // set to important to overwrite bulmas message body border-width
  border-width: var(--border-width) !important
  border-style: solid

.has-border-left
  border-left-width: var(--border-width)
  border-left-style: solid
.has-border-right
  border-right-width: var(--border-width)
  border-right-style: solid
.has-border-top
  border-top-width: var(--border-width)
  border-top-style: solid
.has-border-bottom
  border-bottom-width: var(--border-width)
  border-bottom-style: solid
