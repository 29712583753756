// Bulma Divider modifications
// @see https://bulma-divider.netlify.app/get-started/
.divider
  &.is-empty,
  &.has-no-gap
    --divider-margin-inner-size: 0

// Bulma notification modifications
.notification > .delete
  z-index: 10
