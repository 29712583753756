.is-collapsible
  overflow-y: hidden
  transition: height var(--collapse-transition-speed) var(--collapse-transition-style)

  &.is-active
    transition: height var(--collapse-transition-speed) var(--collapse-transition-style)

  &.message-body
    padding: 0 !important
    .message-body-content
      padding: var(--message-body-padding)
