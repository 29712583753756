.fino-bank-search
  // Apply ng-select style adjustments to select options
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option
    padding: var(--bank-search-option-padding)

  // Sizing bank logos
  .image
    background-color: var(--bank-search-image-background-color)
    border-radius: var(--radius)
    overflow: hidden
    flex-basis: auto
    min-width: var(--bank-search-image-size)
    max-width: var(--bank-search-image-size)

    img
      margin: 0 auto
      max-height: var(--bank-search-image-size)
      width: auto
