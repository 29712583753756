.fino-ui-signature
  .signature-container
    width: 100%

  .options-container
    border-top: 1px solid var(--grey-lighter)
    width: 100%

  .option
    border-left: 1px solid var(--grey-lighter)

    &:first-child
      border-left: 0

    &:hover
      color: var(--link)
