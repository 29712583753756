@use '../mixins' as *

//
// .is-* modifiers
//

.is-absolute
  position: absolute

.is-centered
  margin-left: auto
  margin-right: auto

.is-clickable
  cursor: pointer

.is-clickable-mobile
  cursor: default
  +mobile
    cursor: pointer

.is-not-clickable
  cursor: default !important

.is-fullheight
  min-height: 100%

.is-fullwidth
  width: 100%

.is-fullscreen
  height: 100%
  width: 100%

.is-overflow-hidden
  overflow: hidden

.is-overflow-scrollable
  overflow-y: auto

.is-transparent
  background-color: transparent

.is-text-normal
  font-style: normal

.is-human-readable-text
  font-family: monospace

.is-fullrounded
  border-radius: 100%

//
// .has-* modifiers
//

.has-background-primary-transparent
  background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.5)

.has-circle
  display: flex
  justify-content: center
  background-color: var(--primary)
  border: 1px solid var(--primary)
  border-radius: 50%
  color: var(--white)

.has-hover-focus
  border-style: solid
  border-color: var(--border-color)
  border-width: var(--border-width)
  transition-duration: var(--duration)
  transition-property: border-color, box-shadow
  will-change: border, box-shadow
  &:hover,
  &:focus
    border-color: hsl(var(--focus-h),var(--focus-s),var(--focus-l))
    box-shadow: var(--focus-shadow-size) hsla(var(--focus-h),var(--focus-s),var(--focus-l),var(--focus-shadow-alpha))

.has-break-word
  overflow-wrap: break-word
  word-break: break-word

.has-break-spaces
  white-space: break-spaces !important

.has-no-white-space
  white-space: nowrap !important

.has-no-wrap
  white-space: nowrap

.has-pre-line
  white-space: pre-line

.has-no-border
  border: none !important

.has-no-border-top-radius
  border-top-left-radius: 0
  border-top-right-radius: 0

.has-no-border-bottom-radius
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0

.has-no-border-left-radius
  border-top-left-radius: 0
  border-bottom-left-radius: 0

.has-no-border-right-radius
  border-top-right-radius: 0
  border-bottom-right-radius: 0

.has-dropdown
  overflow: visible !important

.has-overflow-auto
  overflow: auto !important

.has-overflow-hidden
  overflow: hidden !important

.has-overflow-scroll
  overflow: scroll !important

.has-overflow-visible
  overflow: visible !important

.has-overflow-x-visible
  overflow-x: visible !important

.has-shadow
  box-shadow: var(--shadow)

.has-shadow-invert
  box-shadow: var(--shadow-invert)

.has-text-weak
  color: var(--text-weak)

.has-no-transform
  transform: none !important

.has-width-auto
  width: auto

//
// .table modifiers
//

.table
  &.has-no-border
    th, td
      border: none
  .has-no-border
    > th, td
      border: none
