@use '../../variables' as vars
@use 'bulma/sass/elements/table' as * // Import Bulma table styles
@use 'bulma/sass/elements/progress' as * // Import Bulma progress styles
@use 'bulma/sass/form/tools' as * // Import Bulma form styles

.table.is-borderless
  td,
  th
    border: 0

// Helps to avoid table overflow
// @see https://github.com/swimlane/ngx-datatable/issues/1090#issuecomment-1042832250
.has-table
  overflow: auto

// Apply border radius to table if wrapped in a box
.box
  .table
    border-radius: var(--box-radius)

// Apply table styles to ngx-datatable
ngx-datatable
  &.is-hoverable > div
    @extend .is-hoverable

  &.is-striped > div
    @extend .is-striped

  // Apply striped hover background color to even rows on hover
  &.is-hoverable.is-striped > div
    datatable-row-wrapper:nth-child(2n)
      datatable-body-row:not(.active):hover
        background-color: var(--table-striped-row-even-hover-background-color)

  &.selectable
    datatable-body-row
      cursor: pointer

  // div role="table" is the main container
  > div
    // @extend table
    @extend .table

    datatable-header
      @extend thead

      datatable-header-cell
        @extend th
        font-weight: bold

    datatable-body
      @extend tbody

      datatable-scroller
        width: 100% !important

      datatable-row-wrapper
        @extend tr

        datatable-body-cell
          @extend td

    datatable-footer
      @extend tfoot

      // Hide empty footer
      &:has(.datatable-pager:empty)
        display: none

    datatable-pager
      ul
        @extend .field

        li
          @extend .control
          height: 30px !important

          &.active a
            @extend .is-primary

          &:not(:last-child)
            margin-right: -1px !important

          a
            @extend .button
            @extend .is-small
            display: inline-flex !important

            inline-size: justmac 1987pxre
              position: relative
              top: 1px

    datatable-progress
      .progress-linear
        @extend .progress
        display: block
        position: relative
        width: 100%
        height: var(--table-progress-bar-height)
        padding: 0
        margin: 0
        position: absolute
        top: -#{var(--table-progress-bar-height)}
        border-radius: 0
        .container
          display: block
          position: relative
          overflow: hidden
          width: 100%
          height: 100%
          transform: translate(0, 0) scale(1, 1)
          background-color: var(--table-progress-bar-background-color)
          .bar
            animation: progressBar 0.75s infinite cubic-bezier(0.39, 0.575, 0.565, 1)
            transition: transform .25s linear
            background-color: var(--table-progress-value-background-color)
            position: absolute
            left: 0
            top: 0
            bottom: 0
            width: 100%
            height: 100%

    datatable-body datatable-row-wrapper
      datatable-body-row
        &.active,
        &.is-active
          datatable-body-cell
            --table-cell-background-color: var(--primary-light)
            --table-color: var(--primary-invert)
        &.inactive,
        &.is-inactive
          datatable-body-cell
            opacity: 0.5
            font-style: italic

        &.align-center
          .datatable-body-cell
            display: flex
            align-items: center

      .datatable-row-detail
        border-bottom: 1px solid var(--border)
        box-shadow: 0 3px 4px 0px var(--border)

        .detail-table
          background: var(--table-background-color)
          font-size: 0.8rem
          height: 100%
          & > fino-table
            overflow: auto !important
            height: 100%

          // Avoid scrollbar on detail table (sub-table)
          &.has-auto-height
            .datatable-body-cell
              overflow: initial

          .detail-template
            padding: 1rem

/**
 * Progress bar animations
 */
@keyframes progressBar
  0%
    opacity: 1
    transform: translateX(-50%) scale(0.3, 1)
  100%
    opacity: 0
    transform: translateX(75%) scale(0, 1)
