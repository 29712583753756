@use '../mixins' as *

[hidden]
  display: none

// Visible beats hidden
.is-hidden
  &.is-visible
    display: block !important
  +mobile
    &.is-visible-mobile
      display: block !important

// Hide something but not important
.is-hidden-soft
  display: none

// Pendent to @media print version to show something only in print
.is-only-visible-print
  display: none

/**
 * Dirty hack to make the necessary submit input invisible but still focusable
 */
.input-invisible
  height: 1px
  width: 1px
  border: 0
  background: none
  position: absolute
  left: -9999px
  visibility: visible !important

.hide-empty
  &:empty
    display: none
